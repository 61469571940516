<template>
  <div class="index_wrapper">
    <!-- 导航 -->
    <header-box
      @changeMenuIndex="changeMenuIndex"
      :user="user"
      :meunCurrentIndex="meunCurrentIndex"
      @handleLogout="handleLogout"
      @goToPurchase="goToPurchase"
      @goRegister="goRegister"
    ></header-box>
    <section class="banner">
      <!-- 轮播图 -->
      <a-carousel autoplay :autoplaySpeed="5000" class="banner_carousel">
        <div @click="openConcat">
          <img src="@/assets/Banner02.png" />
        </div>
        <div
          @click="
            () => {
              $router.push({ path: '/join', query: { ...query } });
            }
          "
        >
          <img src="@/assets/Banner03.png" />
        </div>
        <div @click="goToAccount">
          <img src="@/assets/Banner04.png" />
        </div>
      </a-carousel>
      <!-- 用户操作 -->
      <div class="banner_content">
        <div class="usermenu">
          <!-- 2.登录 -->
          <div class="usermenu_top" v-if="user">
            <div class="user_icon">
              <img src="@/assets/icon／用户2.png" alt />
            </div>
            <div class="title">Hi,{{ user.nickname }}</div>
            <div class="login_btn user_btn" @click="goToPurchase">
              进入礼品商城
            </div>
          </div>
          <!--1. 未登录 -->
          <div class="usermenu_top" v-else>
            <div class="user_icon">
              <img src="@/assets/icon／用户.png" alt />
            </div>
            <div class="title">Hi,欢迎来到{{ baseData.systemTitle }}~</div>

            <span
              class="login_btn user_btn"
              @click="
                () => {
                  $router.push({ name: 'login' });
                }
              "
              >登录</span
            >

            <span class="register_btn user_btn" @click="goRegister"
              >免费注册</span
            >
          </div>

          <div class="usermenu_bottom">
            <div class="usermenu_bottom_item" @click="openConcat">
              <div class="usermenu_sprite usermenu_icon1"></div>
              <div>在线客服</div>
            </div>
            <div class="usermenu_bottom_item" @click="openConcat">
              <div class="usermenu_sprite usermenu_icon2"></div>
              <div>帮助中心</div>
            </div>
            <div class="usermenu_bottom_item" @click="goToAccount">
              <div class="usermenu_sprite usermenu_icon3"></div>
              <div>邀请好友</div>
            </div>
            <a class="usermenu_bottom_item" href="#daifa_tips">
              <div class="usermenu_sprite usermenu_icon4"></div>
              <div>代发指南</div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <div class="hot_list">
      <div class="content">
        <div class="hot_title">
          <div class="hot_title_left">
            <span>热销榜单</span>
            <span>物美价廉，众多商家共同的选择~</span>
          </div>
          <div class="hot_title_right" @click="goToPurchase">
            <span>更多礼品></span>
          </div>
        </div>
        <div class="hot_body">
          <div class="hot_body_left" v-if="goods.length > 0">
            <div class="hot_body_left_t">
              <img src="@/assets/img／人气爆款.png" alt class="hot_logo" />
              <div class="hot_title_left">{{ goods[0].name }}</div>
              <div class="hot_price">￥{{ goods[0].price }}</div>
              <div class="buynow_btn" @click="goToPurchase">立即选购</div>
            </div>
            <div class="hot_body_left_b">
              <img
                :src="goods[0].picture"
                alt
                @click="goToPurchase"
                style="cursor: pointer"
              />
              <div class="item_desc">
                <div class="name">{{ addressObj[goods[0].uid] }}</div>
                <div class="site">{{ Number(goods[0].weight) * 1000 }}g/件</div>
              </div>
            </div>
          </div>
          <div class="hot_body_right">
            <div
              class="hot_body_item"
              v-for="(item, index) in goods"
              :key="index"
              v-if="index > 0 && index < 9"
            >
              <img
                :src="item.picture"
                alt
                @click="goToPurchase"
                style="cursor: pointer"
              />
              <div class="item_info">
                <div class="price">￥{{ item.price }}</div>
                <div class="weight">{{ Number(item.weight) * 1000 }}g/件</div>
              </div>
              <div class="item_desc">
                <div class="name">{{ item.name }}</div>
                <div class="site">{{ addressObj[item.uid] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="invite">
      <div class="content">
        <div class="invite_title">邀请好友，赚佣金</div>
        <div class="invite_tips">
          <div class="invite_num">
            <img src="@/assets/img／1 (1).png" alt />
            <div>分享邀请链接给好友</div>
          </div>
          <img class="invite_jiantou" src="@/assets/img／箭头 (2).png" alt />
          <div class="invite_num">
            <img src="@/assets/img／2 (1).png" alt />
            <div>好友注册并成功下单</div>
          </div>
          <img class="invite_jiantou" src="@/assets/img／箭头 (2).png" alt />
          <div class="invite_num">
            <img src="@/assets/img／3 (1).png" alt />
            <div>佣金到账，马上提现（{{ baseData.inviter }}元/单）</div>
          </div>
        </div>

        <div class="invitenow_btn" @click="goToAccount">
          <span>立即邀请好友</span>
          <img src="@/assets/img／金币.png" alt />
        </div>
      </div>
    </div>
    <div class="daifa_tips" id="daifa_tips">
      <div class="wrapper">
        <div class="title">代发指南</div>
        <div class="tips_list">
          <div class="line"></div>
          <div class="tips_item">
            <div class="tips_icon">
              <div class="tips_sprite1"></div>
            </div>
            <div class="tip_text">
              <div class="tip_title">选择来源</div>
              <div class="tip_subtitle">
                选择数据来源平台
                ，支持天猫、淘宝、阿里巴巴、拼多多、京东等各大平台
              </div>
            </div>
          </div>
          <div class="tips_item">
            <div class="tips_icon">
              <div class="tips_sprite2"></div>
            </div>
            <div class="tip_text">
              <div class="tip_title">录入信息</div>
              <div class="tip_subtitle">
                录入收件人信息，支持模板导入、人工填写
              </div>
            </div>
          </div>
          <div class="tips_item">
            <div class="tips_icon">
              <div class="tips_sprite3"></div>
            </div>
            <div class="tip_text">
              <div class="tip_title">选择礼品</div>
              <div class="tip_subtitle">
                根据需要选择礼品，满足各种需求
              </div>
            </div>
          </div>
          <div class="tips_item">
            <div class="tips_icon">
              <div class="tips_sprite4"></div>
            </div>
            <div class="tip_text">
              <div class="tip_title">查看快递</div>
              <div class="tip_subtitle">
                确认信息无误后，提交订单，可随时查看快递单号
              </div>
            </div>
          </div>
        </div>
        <div class="choosetobuy" @click="goToPurchase">立即选购礼品</div>
      </div>
      <div
        class="company_info"
        v-if="baseData.content"
        v-html="baseData.content"
      >
        <!-- <div v-html="baseData.content"></div> -->
      </div>
    </div>
    <footer :class="baseData.content ? 'fot' : ''">
      <div class="content">
        <div class="list-wrapper">
          <div class="list">
            <div class="icon">
              <div class="foot_sprite sprite1"></div>
            </div>
            <div class="text">
              <span class="text_title">正品保障</span>
              <span class="text_subtitle">正品保障，放心选购</span>
            </div>
          </div>
          <div class="list">
            <div class="icon">
              <div class="foot_sprite sprite2"></div>
            </div>
            <div class="text">
              <span class="text_title">天天低价</span>
              <span class="text_subtitle">天天低价，畅选无忧</span>
            </div>
          </div>
          <div class="list">
            <div class="icon">
              <div class="foot_sprite sprite3"></div>
            </div>
            <div class="text">
              <span class="text_title">多仓直发</span>
              <span class="text_subtitle">多仓直发，急速配达</span>
            </div>
          </div>
          <div class="list">
            <div class="icon">
              <div class="foot_sprite sprite4"></div>
            </div>
            <div class="text">
              <span class="text_title">品类齐全</span>
              <span class="text_subtitle">品类齐全，轻松选礼</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="statement">
      <div>
        声明：本站产品均为商家辅助使用
      </div>
      <div>
        禁止使用于违法或侵害他人权益行为，若有立马封号处理，余额不退还，情节严重者，本站积极协助公安机关调查！
      </div>
    </div>
    <div class="foot_link">
      <div class="copyright">
        Copyright © 2020 {{ baseData.bottom }} 版权所有
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="color: rgba(0, 0, 0, 0.45);"
          >{{ baseData.beian }}</a
        >
      </div>
    </div>
    <div class="concat_side">
      <div
        class="kefu"
        @click="openConcat"
        @mouseenter="
          () => {
            kefuShow = true;
          }
        "
        @mouseleave="
          () => {
            kefuShow = false;
          }
        "
      >
        <div v-if="kefuShow" class="side_sprite1"></div>
        <div v-else class="side_sprite11"></div>
        <span :class="kefuShow ? 'fontred' : ''">客服</span>
        <div v-show="kefuShow" class="kefu_img">在线客服</div>
      </div>
      <div
        v-show="baseData.qq"
        @click="openqq"
        class="qq"
        @mouseenter="
          () => {
            qqImgShow = true;
          }
        "
        @mouseleave="
          () => {
            qqImgShow = false;
          }
        "
      >
        <a-icon
          type="qq"
          v-if="qqImgShow"
          style="font-size: 26px; color: #f93244"
        />
        <a-icon type="qq" v-else style="font-size: 26px" />
        <span :class="qqImgShow ? 'fontred' : ''">QQ</span>
        <div v-show="qqImgShow" class="qq_img">QQ交谈</div>
      </div>
      <div
        v-show="baseData.wechat"
        class="wechat"
        @mouseenter="
          () => {
            wechatImgShow = true;
          }
        "
        @mouseleave="
          () => {
            wechatImgShow = false;
          }
        "
      >
        <a-icon
          type="wechat"
          v-if="wechatImgShow"
          style="font-size: 26px; color: #f93244"
        />
        <a-icon type="wechat" v-else style="font-size: 26px" />
        <span :class="wechatImgShow ? 'fontred' : ''">微信</span>
        <div v-show="wechatImgShow" class="wechat_img">
          <img
            border="0"
            :src="baseData.wechat"
            alt="扫码加好友"
            title="扫码加好友"
          />
        </div>
      </div>
      <div
        class="top"
        @click="scrollToTop"
        @mouseenter="
          () => {
            topShow = true;
          }
        "
        @mouseleave="
          () => {
            topShow = false;
          }
        "
      >
        <div v-if="topShow" class="side_sprite2"></div>
        <div v-else class="side_sprite22"></div>
        <span :class="topShow ? 'fontred' : ''">顶部</span>
      </div>
      <div
        class="mobile"
        @click="gotomobile"
        @mouseenter="
          () => {
            mobile = true;
          }
        "
        @mouseleave="
          () => {
            mobile = false;
          }
        "
      >
        <img v-if="mobile" src="@/assets/mobile_active.png" />
        <img v-else src="@/assets/mobile.png" />
        <span :class="topShow ? 'fontred' : ''">移动H5</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { sipUser, addressList, goodsListAll } from "@/api";
import HeaderBox from "@/components/HeaderBox/index";
export default {
  data() {
    return {
      meunCurrentIndex: 0,
      baseData: baseData,
      goods: [],
      user: null,
      addressObj: {},
      wechatImgShow: false,
      qqImgShow: false,
      topShow: false,
      kefuShow: false,
      mobile: false,
      query: {},
      inviterCode: Vue.ls.get("DEFAULT_INVITE_KEY")
    };
  },

  mounted() {
    this.getUrlQuery();
    //获取用户
    sipUser().then(res => {
      this.user = res.data;
    });

    //礼品
    this.handleGetGoodList();
  },

  methods: {
    async handleGetGoodList() {
      const res = await addressList("");
      let address = res.data;
      let obj = {};
      res.data.forEach(item => {
        let uid = item.uid;
        let site = item.province + item.city;
        obj[uid] = site;
      });
      this.addressObj = obj;
      const good = await goodsListAll({ pageSize: 1000 });
      const data = good.data;
      let arr = [];
      address.forEach(item => {
        if (item.uid) {
          arr.push(item.uid);
        }
      });
      data.list = data.list.filter(item => {
        return arr.indexOf(item.uid) > -1;
      });
      this.goods = data.list;
    },
    gotomobile() {
      localStorage.setItem("agents", "mobile");
      window.location.href = window.location.origin + "/mobile";
    },
    getUrlQuery() {
      if (this.$route.query.c) {
        this.$store.dispatch("ToggleInviteKey", this.$route.query.c);
      }
    },
    goToPurchase() {
      this.$router.push({ path: "/goods/purchase", query: { ...this.query } });
    },
    goToAccount() {
      this.$router.push({ path: "/account/base", query: { ...this.query } });
    },

    ...mapActions(["Logout"]),

    // 回到顶部
    scrollToTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    // 修改索引
    changeMenuIndex(item) {
      if (item.path.indexOf("static.wodh.cn") > -1) {
        window.open(item.path);
      } else {
        setTimeout(() => {
          this.$router.push({ path: item.path });
        }, 200);
        this.meunCurrentIndex = item.index;
      }
    },
    handleLogout() {
      const that = this;
      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        onOk() {
          return that
            .Logout({})
            .then(() => {
              window.location.reload();
            })
            .catch(err => {
              that.$message.error({
                title: "错误",
                description: err.message
              });
            });
        },
        onCancel() {}
      });
    },
    openConcat() {
      window.open(
        "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97817c5390744c9abd4ae0e0344549e2a29e6fdc2fdf2b2137a1eaebae5c0f1ad81b0ab6a09db04a4b9520996e25ff7c"
      );
    },
    openqq() {
      window.open(
        `http://wpa.qq.com/msgrd?v=3&uin=${baseData.qq}&site=qq&menu=yes`
      );
    },
    goRegister() {
      if (this.inviterCode) {
        this.$router.push({ name: "register", query: { c: this.inviterCode } });
      } else {
        this.$router.push({ name: "register" });
      }
    }
  },
  components: {
    HeaderBox
  }
};
</script>

<style lang="less" scoped>
.index_wrapper {
  min-width: 1200px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  position: relative;
  font-family: PingFang SC;

  .content {
    width: 1200px;
    margin: 0 auto;
  }

  //banner
  .banner {
    position: relative;
    cursor: pointer;
    .banner_carousel {
      img {
        display: block;
        width: 100%;
        height: 560px;
        object-fit: cover;
      }
      /deep/ .slick-dots {
        bottom: 40px;
        li {
          margin-right: 19px;
          button {
            width: 48px;
            height: 4px;
          }
        }
      }
    }
    .banner_content {
      width: 258px;
      height: 452px;
      position: absolute;
      left: 0;
      transform: translateX(-180%);
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      .usermenu {
        width: 258px;
        height: 452px;
        background: #fff;
        opacity: 1;
        border-radius: 16px;
        .usermenu_top {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          height: 268px;
          .user_icon {
            margin-top: 24px;
            margin-bottom: 16px;
            img {
              width: 72px;
              height: 72px;
            }
          }

          .title {
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
            margin-bottom: 24px;
          }
          .user_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
            width: 210px;
            height: 36px;
            border-radius: 4px;
            cursor: pointer;
          }
          .login_btn {
            border: 1px solid rgba(234, 36, 47, 1);
            color: rgba(234, 36, 47, 1);
          }

          .register_btn {
            color: #fff;
            background-color: #ea242f;
            margin-bottom: 24px;
          }
        }

        .usermenu_bottom {
          border-top: 1px solid #d9d9d9;
          display: flex;
          flex-wrap: wrap;

          .usermenu_bottom_item {
            cursor: pointer;
            overflow: hidden;
            width: 50%;
            height: 91px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            box-sizing: border-box;
            .usermenu_sprite {
              width: 32px;
              height: 32px;
              margin-bottom: 8px;
            }
            .usermenu_icon1 {
              background: url("~@/assets/css_sprites.png") -590px -426px;
            }
            .usermenu_icon2 {
              background: url("~@/assets/css_sprites.png") -530px -510px;
            }
            .usermenu_icon3 {
              background: url("~@/assets/css_sprites.png") -590px -374px;
            }
            .usermenu_icon4 {
              background: url("~@/assets/css_sprites.png") -590px -10px;
            }
            &:nth-child(2n) {
              border-left: 1px solid #d9d9d9;
            }
            &:nth-child(-n + 2) {
              border-bottom: 1px solid #d9d9d9;
            }
          }
        }
      }
    }
  }

  .hot_list {
    margin-top: 40px;
    margin-bottom: 48px;
    .hot_title {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .hot_title_left {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          &:nth-child(1) {
            margin-right: 24px;
            font-size: 30px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
      .hot_title_right {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    .hot_body {
      display: flex;
      height: 606px;
      .hot_body_left {
        width: 232px;
        margin-right: 10px;
        background-color: #fff;
        position: relative;
        .hot_body_left_t {
          padding: 104px 0 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .hot_logo {
            position: absolute;
            top: -4px;
            left: -5px;
          }
          .hot_title_left {
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 8px;
            text-align: center;
          }
          .hot_price {
            font-size: 24px;
            color: rgba(251, 52, 52, 1);
            margin-bottom: 32px;
          }
          .buynow_btn {
            width: 168px;
            height: 40px;
            background: rgba(251, 52, 52, 1);
            border-radius: 20px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
        .hot_body_left_b {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          img {
            width: 250px;
            height: 250px;
            margin-bottom: 8px;
          }
          .item_desc {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 32px 33px;
            .name {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.65);
            }
            .site {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
      .hot_body_right {
        width: calc(1200px - 232px - 10px);

        // background-color: pink;
        .hot_body_item {
          margin-right: 10px;
          float: left;
          width: 232px;
          height: 298px;
          background-color: #fff;
          padding: 16px;
          margin-bottom: 10px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          img {
            width: 200px;
            height: 200px;
            margin-bottom: 16px;
          }
          .item_info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            align-items: center;
            .price {
              font-size: 18px;
              font-weight: bold;
              color: rgba(251, 52, 52, 1);
            }
            .weight {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.65);
            }
          }
          .item_desc {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.65);
              // width: 100px;
              // overflow: hidden;
              height: 20px;
            }
            .site {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
  }
  .invite {
    height: 605.38px;
    background-image: url(~@/assets/bg／邀好友，赚佣金.png);
    background-size: cover;
    .content {
      padding: 80px 0 130px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .invite_title {
        font-size: 30px;
        margin-bottom: 51px;
        color: #fff;
      }
      .invite_tips {
        margin-bottom: 80px;
        display: flex;
        width: 1200px;
        justify-content: space-around;
        .invite_num {
          width: 384px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          div {
            margin-top: 24px;
            font-size: 20px;
            color: #fff;
          }
        }
        .invite_jiantou {
          width: 64px;
          height: 24px;
          margin-top: 48px;
        }
      }

      .invitenow_btn {
        width: 274px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 40px;
        padding-right: 36px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 3px 6px rgba(255, 255, 255, 0.16);
        border-radius: 28px;
        color: #e6155c;
        font-size: 24px;
        cursor: pointer;
        span {
          margin-right: 16px;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
  .daifa_tips {
    background-image: url("~@/assets/bg／代发指南.png");
    background-color: #fff;
    position: relative;
    .wrapper {
      width: 1200px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0 auto;
      padding-top: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 30px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
      .tips_list {
        margin-top: 80px;
        margin-bottom: 64px;
        display: flex;
        position: relative;
        .line {
          position: absolute;
          top: 44px;
          left: 62px;
          width: 1013px;
          height: 8px;
          background: rgba(253, 235, 231, 1);
        }

        .tips_item {
          display: flex;
          align-items: center;
          // justify-content: center;
          flex-direction: column;
          width: 218px;
          height: 240px;
          z-index: 9999;
          margin-right: 88px;

          &:last-child {
            margin-right: 0;
          }
          .tips_icon {
            width: 96px;
            height: 96px;
            border-radius: 50%;
            background-color: #feeee7;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .tips_sprite1 {
            width: 56px;
            height: 56px;
            background-image: url("~@/assets/css_sprites.png");
            background-position: -430px -382px;
          }
          .tips_sprite2 {
            width: 56px;
            height: 56px;
            background-image: url("~@/assets/css_sprites.png");
            background-position: -514px -86px;
          }
          .tips_sprite3 {
            width: 56px;
            height: 56px;
            background-image: url("~@/assets/css_sprites.png");
            background-position: -514px -10px;
          }
          .tips_sprite4 {
            width: 56px;
            height: 56px;
            background-image: url("~@/assets/css_sprites.png");
            background-position: -514px -162px;
          }

          .tip_text {
            display: flex;
            align-items: center;
            flex-direction: column;

            .tip_title {
              margin: 24px 0;
              font-size: 20px;
              font-weight: bold;
              color: rgba(251, 52, 52, 1);
            }

            .tip_subtitle {
              text-align: center;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
      .choosetobuy {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 274px;
        height: 56px;
        background: linear-gradient(
          180deg,
          rgba(254, 117, 13, 1) 0%,
          rgba(230, 21, 53, 1) 100%
        );
        box-shadow: 0px 3px 6px rgba(255, 255, 255, 0.16);
        border-radius: 30px;
        color: #fff;
        font-size: 24px;
        margin-bottom: 232px;
        cursor: pointer;
      }
    }
    .company_info {
      position: absolute;
      top: 658px;
      left: 0;
      right: 0;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
    }
    /deep/ .com_title {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 40px;
    }
    /deep/ .com_desc {
      font-size: 18px;
    }
  }
  footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    .list-wrapper {
      display: flex;

      .list {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 282px;
        height: 140px;
        margin-right: 24px;
        &:last-child {
          margin: 0;
        }
        .icon {
          width: 56px;
          height: 56px;
          margin-right: 16px;
          border-radius: 50%;
          background: #fb3434;
          display: flex;
          justify-content: center;
          align-items: center;
          .foot_sprite {
            width: 40px;
            height: 40px;
          }
          .sprite1 {
            background: url("~@/assets/css_sprites.png") -70px -510px;
          }
          .sprite2 {
            background: url("~@/assets/css_sprites.png") -130px -510px;
          }
          .sprite3 {
            background: url("~@/assets/css_sprites.png") -190px -510px;
          }
          .sprite4 {
            background: url("~@/assets/css_sprites.png") -250px -510px;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          .text_title {
            font-size: 20px;
            color: rgba(251, 52, 52, 1);
            margin-bottom: 4px;
          }
          .text_subtitle {
            font-size: 12px;
            color: rgba(251, 52, 52, 1);
          }
        }
      }
      .link {
        padding: 16px 0 25px 0;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .fot {
    padding-top: 100px;
  }
  .statement {
    background-color: #fff;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    padding-top: 24px;
  }
  .foot_link {
    color: rgba(0, 0, 0, 0.45);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 73px;
  }
  .concat_side {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 72px;
    padding: 0 8px;
    background-color: #fff;
    border-radius: 8px;
    .fontred {
      color: #f93244;
    }
    .kefu,
    .qq,
    .wechat,
    .top,
    .mobile {
      height: 72px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      img {
        width: 23px;
        height: 25px;
        margin-bottom: 8px;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
      }
      &:last-child {
        border: 0;
      }
    }
    .wechat {
      position: relative;
      .wechat_img {
        background-image: url("../assets/联合 12.png");
        background-repeat: no-repeat;
        background-size: 120px 120px;
        position: absolute;
        top: -10px;
        left: -130px;
        width: 120px;
        height: 120px;
        text-align: center;
        background-size: 120px 120px;
        padding-top: 4.5px;
        padding-right: 5px;
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
    .qq {
      position: relative;
      .qq_img {
        background-image: url("../assets/联合 11.png");
        background-repeat: no-repeat;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-size: 100px 40px;
        position: absolute;
        top: 20px;
        left: -110px;
        padding-right: 5px;
      }
    }

    .kefu {
      position: relative;
      .kefu_img {
        background-image: url("../assets/联合 11.png");
        background-repeat: no-repeat;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-size: 100px 40px;
        position: absolute;
        top: 20px;
        left: -110px;
        padding-right: 5px;
      }
      .side_sprite1 {
        width: 30px;
        height: 30px;
        background: url("~@/assets/css_sprites.png") -590px -166px;
      }
      .side_sprite11 {
        width: 30px;
        height: 30px;
        background: url("~@/assets/css_sprites.png") -590px -218px;
      }
    }
    .side_sprite2 {
      width: 30px;
      height: 30px;
      background: url("~@/assets/css_sprites.png") -590px -62px;
    }
    .side_sprite22 {
      width: 30px;
      height: 30px;
      background: url("~@/assets/css_sprites.png") -590px -114px;
    }
  }
}
</style>
